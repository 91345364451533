@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "./styles/variables";
@import "../src/styles/table.scss";
@import "../src/styles/sidebar.scss";
@import "../src/styles/modal.scss";
@import "../src/styles/button.scss";

html {
  width: 100%;
  height: 100%;
}

body {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)),
    url("./static/images/loginBackground.jpg")
      no-repeat top center fixed;
  -webkit-background-size: 100% 110%;
  -moz-background-size: 100% 110%;
  -o-background-size: 100% 110%;
  background-size: 100% 110%;

  overflow: hidden;
  font-family: "Lato", sans-serif !important;
  background-position: center;
  height: 100vh;
  font-size: 10px !important;
}
#root {
  width: 100%;
  height: 100vh;
  position: relative;
}

.loading-indicator:before {
  content: "";
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: "Loading";
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}
.header-color {
  background-color: $dashboard-light-2;
  color: $dashboard-font-1;
}

.container-fluid {
  height: calc(100% - 40px) !important;
  overflow: hidden !important;
  padding: 15px !important;
}
.main-menu {
  color: #ebebeb !important;
  letter-spacing: 1px;
  border-radius: 0;
  background-color: $dashboard-dark-2;
  font-size: 10px;
  line-height: 1.42857143;
  height: calc(100% - 40px) !important;
}
.h-inherit{
  height: inherit;
}
.h-32px{
  height: 32px;
}

::-webkit-scrollbar-track {
  background: $dashboard-font-2;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  background: #808080;
}


.react-confirm-alert-overlay {
  background: #343a4080!important;
}

.dropdown , .table-dropdown {
  color: transparent !important;
  height: 24px;
  display: inline-block;
  line-height: 24px;
  font-size: 12px;
  line-height: 1;
  background: transparent;
  margin-right: 5px;
  border-radius: 0;
  background-color:transparent !important;
}

.react-datepicker__header,
.react-datepicker {
  background-color: $dashboard-light-2 !important;
  color: white !important;
}

.dropdown-toggle,
.dropdown-item,
.color-white,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: white !important;
}
.dropdown-toggle {
  border: 1px solid #888 !important;
  padding: 5px !important;
  margin-top: 0px !important;
  font-size: 10px !important;
  width: 100% !important;
  text-align: left !important;
}
.react-datepicker-wrapper {
  display: inline;
  margin-left: 5px;
  background: transparent;
  color: $dashboard-font-2;
  padding: 0px 5px 5px 5px !important;
}
.dropdown-item:hover {
  background-color: #df691a !important;
}

.dropdown-menu,
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: $dashboard-dark-2 !important;
}

.react-datepicker__input-container input {
  border: 1px solid #888 !important;
  font-size: 12px;
  border-radius: 0.25rem;
  padding: 5px;
}
.react-datepicker,
.react-datepicker__current-month,
.dropdown-item {
  font-size: 10px !important;
}

.react-datepicker__input-container input {
  background: transparent;
  border: 1px solid transparent;
  color: white;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0px !important;
  width: 1.5rem !important;
  line-height: 1.5rem !important;
}

.nav-item .active {
  width: auto !important;
  color: $dashboard-font-2 !important;
  background-color: #df691a !important;
  border-color: unset !important;
}

.nav-item .nav-link {
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 0;
  position: relative;
  padding: 10px 20px !important;
  border: unset !important;
  height: 100%;
  width: 100%;
  line-height: 1.5 !important;
  font-size: 10px !important;
}

.nav-tabs {
  border-bottom: unset !important;
}

.tab-content-custom {
  padding: 15px 0px;
  overflow-x: hidden;
  overflow-y: auto;
  line-height: 1.42857143;
  color: #ebebeb;
}

.bond .react-datepicker-wrapper {
  width: 100%;
  font-size: 1rem;
  color: #778b9e;
  background-color: $dashboard-font-2;
  display: inline;
  padding: 2px 0px !important;
  margin-left: 0;
}
.bond .react-datepicker-wrapper .react-datepicker__input-container input {
  border: unset !important;
  width: 100%;
  border-radius: unset !important;
  color: #495057 !important;
}
.bond
  .react-datepicker-wrapper
  .react-datepicker__input-container
  .react-datepicker-ignore-onclickoutside {
  outline: unset !important;
}

.clickable {
  cursor: pointer;
}
.grid-input {
  outline: unset;
}
.close:hover {
  color: white !important;
}

.breadcrumb {
  padding: 8px 15px !important;
  margin-bottom: 21px !important;
  list-style: none !important;
  background-color: $dashboard-light-2 !important;
  border-radius: 0 !important;
}
.breadcumtext {
  padding: 1rem 0rem 0rem 3rem;
  color: #ebebeb;
  font-size: 14px;
}

.breadcrumb li .fa {
  padding: 0px 5px 0px 5px;
}

.active-nav {
  text-decoration: none;
  color: $dashboard-font-2;
  background: rgba(255, 255, 255, 0.2);
}

.nav-dropdown-tab {
  text-align: center !important;
  letter-spacing: 1px !important;
  margin-bottom: 0 !important;
  position: relative !important;
  border: unset !important;
  height: 100% !important;
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
}

.nav-dropdown-tab a {
  font-size: 12px !important;
}
.nav-dropdown-tab a::after {
  display: none !important;
}
.nav-dropdown-tab:hover{
  text-decoration: none !important;
  color: #fff !important;
  background: rgba(255, 255, 255, 0.2) !important;
}
.active-dropdown {
  background: #df691a !important;
}

.nav-tabs .nav-item.show .nav-link {
  background: transparent !important;
}

.tab-container {
  width: 100%;
  height: 100%;
  border: 1px solid #888;
}

.tab-form-list-group {
  position: relative;
}
.tab-list-group {
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 32px;
  left: 0;
}

.tab-list-group-item {
  cursor: pointer !important;
  background: #546575 !important;
  border-bottom: 1px solid #888 !important;
  border-left: 1px solid #888 !important;
  border-right: 1px solid #888 !important;
  font-size: 12px;
  padding: 0.5rem;
  letter-spacing: 1px !important;
}

.tab-content-input {
  border: 1px solid #888 !important;
  font-size: 12px !important;
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
  padding: 5px;
  background: #546575 !important;
  color: $dashboard-font-2 !important;
  letter-spacing: 1px !important;
  height: 41px !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: $dashboard-font-2 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $dashboard-font-2 !important;
}

::placeholder {
  color: $dashboard-font-2 !important;
}

.content-border {
  border: 1px solid #888;
}
.content-dotted-border {
  border-bottom: 1px dotted #6c757d;
}

.chartBox {
  width: inherit !important;
  height: inherit !important;
  padding: 10px;
}

.p-15 {
 padding: 15px 0px 15px 0px !important;
}
.py-15{
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.h-90 {
  height: 90%;
}

.grid-wrapper {
  width: 100%;
  height: 100%;
}

.sidebar {
  .nav-item a {
    text-align: left !important;
  }
}
.button-padding {
  padding: 0px 5px 5px 5px !important ;
  font-size: 12px !important;
}

.button-padding:hover{
  background-color: #485563;
} 

.button-padding .fa {
  padding: 3px;
}
.fa-times-circle{
  font-size: 16px !important;
  cursor: pointer;
}
 
.table-dropdown button {
  background-color: transparent !important;
}
 
.dashboard {
  .dropdown-menu{
    min-width: 12rem !important;  
  }
  .main-tab-wrapper {
    width: 100%;
    .main-tab{
      overflow-x: auto !important;
      overflow-y: hidden !important;
    }
  }
}
span , p {
  font-size: 12px !important;
}
 

.form-control.is-invalid{
  border-color: #dc3545 !important;
}
.react-datepicker-popper { 
  z-index: 9999 !important;
}

.btn , .form-label ,option , .badge {
  font-size: 12px !important;
}

.fa{
  font-size: 10px !important;
}
.modal-body{
  background: #546575;
  color: #fff;
}

.close span{
  font-size: 18px !important;
}
.dropdown-toggle::after{
  margin-top: 7px !important;
}

.tv-lightweight-charts{
  border: 1px solid grey !important;
}

.btn-transparent{
  border: 1px solid grey !important;
  color: #fff !important;
  background: transparent !important;
  width: 100% !important;
}

.sentiment-summary .chartBox{
  height: 250px !important;
  padding: 15px !important;
}