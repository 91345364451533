.table {
  margin-bottom: 0px !important;
}

.panel,
.ag-theme-alpine .panel {
  background-color: #546575 !important;
  border-radius: 0;
}

.table-panel-header {
  display: flex;
}

.row-tables {
  color: white;
  background-color: #2b3e50;
}

.table-title {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 2px;
}

#modalTable {
  padding-left: 15px;
  width: 100%;
  font-size: 12px;
  padding-right: 15px;
}

.paneldecoration {
  padding: 6px;
}
.lastupdateHead {
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
}

.form-group-custom {
  text-align: center;
}

.datepicker {
  z-index: 1500 !important;
}

.lastupdate {
  font-size: 12px;
  letter-spacing: 1px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  padding: 5px 5px 5px 5px;
}
.lastupdateHead {
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
  padding: 5px 0px 0px 0px;
}

.react-table-card {
  background-color: #31475a !important;
  border: unset !important;

  .resizer {
    top: 10%;
    cursor: col-resize;
    position: absolute;
    display: inline-block;
    height: 80%;
    max-height: 50px;
    width: 0.12rem;
    background: #546575;
    right: 0;
    touch-action: none;
    transform: translateX(50%);
  }
  .resizer:hover {
    background: #efefef;
  }

  thead tr {
    background-color: $dashboard-dark-2 !important;
  }
  tbody tr {
    background-color: $dashboard-dark-1 !important;
  }
  tbody tr:nth-child(odd) {
    background-color: $dashboard-light-2 !important;
  }

  .collapse {
    width: 0.0000000001%;
  }
}

.react-table-footer {
  background-color: transparent !important;
}

.table td,
.table th {
  text-align: center;
  font-weight: 500 !important;
  padding: 4px !important;
  border: 1px solid grey !important;
  vertical-align: middle !important ;
  background: $dashboard-dark-2 !important;
  white-space: nowrap !important;
  font-size: 10px !important;
}

.table td {
  background: #485563 !important;
}

.table-input {
  height: 14px !important;
  border-radius: initial !important;
  font-size: 12px !important;
}

.react-table-footer {
  padding: 0px !important;
}

.pagination {
  margin-bottom: 0px !important;
}

.react-table-footer span {
  font-size: 12px;
}

td[data-sticky-td] {
  background-color: $dashboard-dark-2 !important;
}

thead {
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;
}
tbody {
  overflow: visible !important;
}
