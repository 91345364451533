.order {
  
  .card-body {
    background-color: #546575 !important;
  }

  .fa-plus-circle,
  .fa-minus-circle {
    font-size: 16px !important;
    cursor: pointer !important;
    color: #fff !important;
  }

  .tradeDate {
    width: 102px !important;
    min-width: 102px !important;
  }

  .w-td-90 {
    width: 90px !important;
    min-width: 90px !important;
  }

  .w-td-80 {
    width: 80px !important;
    min-width: 80px !important;
  }

  .w-td-60 {
    width: 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
  }

  .w-td-70 {
    width: 70px !important;
    max-width: 70px !important;
    min-width: 70px !important;
  }

  .w-td-100 {
    width: 100px !important;
    max-width: 100px !important;
    min-width: 100px !important;
  }

  .w-td-120 {
    width: 130px !important;
    max-width: 130px !important;
    min-width: 130px !important;
  }
 
  .child-table tr td {
    padding: 4px !important;
  }
  .child-table tr th {
    background: #485563 !important;
  }

  .border-0 {
    border: 0px !important;
  }

  .parent-table tr td , .parent-table tr th{
    border: 0px !important;
  }
  .child-table tr td,
  .child-table tr th {
    border: 1px solid grey !important;
  }

  .child-table tr th:nth-child(12) {
    text-align: center !important;
  }




  .nested-table tr td:nth-child(1) {
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 0px !important;
    border-top: 1px solid #485563 !important ;
  }
  .nested-table tr td{
    padding: 4px !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    border-bottom: 1px solid grey !important;
  }

  .card-body {
    border: 1px solid grey;
  }

  .react-datepicker-popper {
    z-index: 9999 !important;
  }

  .text-ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 90px !important;
    max-width: 90px !important;
  }

  table {
    max-width: 100%;
    overflow: auto;
  }
  .innertable-table {
    table-layout: fixed;
    width: 100%;
  }
  .innertable {
    overflow-x: auto;
    display: block;
  }

  .result-td {
    padding: 4px !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    border-bottom: 1px solid grey !important;
  }
}

@media only screen and (max-width: 1250px) {
  .innertable-table {
    table-layout: unset !important;
    width: unset !important;
  }
  .innertable {
    overflow-x: unset !important;
    display: unset !important;
  }
}



.blotter-tables table tr td{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.blotter-tables table tr td:last-child{
  display: unset !important;
  align-items: unset !important;
  justify-content: unset !important;
}


.watchlist-btn{
  margin-top: 2px;
}