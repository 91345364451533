
.parentTable tr th:nth-child(12){
  text-align: center !important;
}
 

.parentTable colgroup col:nth-child(14){
  min-width:90px !important;
}
 

  .nestedTd{
    padding:  0px !important;
  }

  .expandBtn.focus, .expandBtn:focus{
    box-shadow: none !important;
  }

  .orderSummary{
    height: calc(50% - 8px);
  }

  .cardBody{
    height: calc(100% - 30px) !important;
} 